<div class="flex flex-col w-full h-full">
  <!-- Restaurant Header -->
  <div class=" relative flex flex-col h-[10%] md:h-[200px] w-full">
    <div class="absolute flex flex-col justify-center w-full h-full text-white bg-transparent">
      <span class="text-white font-semibold text-lg md:text-[50px] xl:text-5xl text-center ">{{ outlet?.store
        }}</span>
      <span class="text-xs font-medium text-center text-white md:text-sm xl:text-lg ">{{ outlet?.name
        }}</span>
    </div>
    <img class="object-cover w-full h-full" src={{outlet?.imageUrl}} alt="Outlet image" />
  </div>

  <p-dialog [(visible)]="cartDialog" [style]="{ width: '450px' }" header="Checkout" [modal]="true" styleClass="p-fluid">

    <ng-template pTemplate="content">
      <div class="w-full m-0 space-y-6 md:flex-col md:p-1 lg:p-3 xl:p-4">
        <div class="flex flex-col space-y-3">
          <span class="font-bold">My Cart <span class="text-sm">({{this.cart.length > 1 ? this.cart.length+' orders' :
              cartCount+' item(s)' }})</span></span>
          <!-- Cart list -->
          <div class="flex max-h-[300px] flex-col overflow-auto">
            @for(orderCart of cart; track orderCart; let i = $index){
            @if(this.cart.length > 1){
            <span class="my-2 font-semibold">Order {{ i+ 1}} ({{ orderCart.length }})</span>
            }
            @for(orderItem of orderCart; track orderItem.id){
            <app-cart-item (updateQuantityEmit)="updateCartQuanity($event)"
              (cartItemEmitted)="removeFromCart($event, i)" [cartItem]="orderItem"></app-cart-item>
            }
            }
          </div>
          @if(cartCount > 0){
          <div>
            <span class="font-semibold">Item(s) Cost</span> : {{ ordersCost }}
            <span class="flex font-semibold">Service Charge : {{ serviceCharge }}</span>
            @if(this.outlet?.delivery && this.selectedDeliveryMode === 1){
            <div>
              <span class="font-semibold">Delivery Cost</span> :
              {{ deliveryFee }}
            </div>
            }
            <div>
              <span class="font-bold">Total</span> : {{ totalcost }}
            </div>
          </div>
          <app-delivery-option uniqueId="mobile" [enableDelivery]="this.outlet?.delivery"
            (selectedDeliveryModeEmitter)="deliveryModeChanged($event)"
            [defaultDeliveryMode]="this.selectedDeliveryMode"></app-delivery-option>
          <div class="form_div">
            <button (click)="newOrder()" style="background-color: #E7E8EA"
              class="flex justify-center p-3 space-x-4 duration-300 create_button text-vendblocblue hover:shadow-lg"
              type="submit">
              <span>New Order</span>
            </button>
            <button (click)="checkout()" style="background-color: #0f172a"
              class="flex justify-center p-3 space-x-4 text-white duration-300 create_button hover:shadow-lg"
              type="submit">
              <span>Checkout</span>
            </button>
          </div>
          }
        </div>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="deliveryLocationDialog" [style]="{ width: '450px' }" header="Select your location"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
      <div class="w-full m-0 space-y-6 md:flex-col md:p-1 lg:p-3 xl:p-4">
        <div class="flex flex-col space-y-3">
          @if(this.outlet){
          <form>
            @for (location of this.outlet.deliveryLocations; track $index) {
            <div class="flex justify-between space-y-2">
              <span class="font-bold"> {{location.location}} </span>
              <input [(ngModel)]="selectedDeliveryLocation" (change)="deliveryLoctionChanged($index)" type="radio"
                value={{$index}} class="w-4 h-4 rounded accent-vendblocblue" id={{location.location}}{{$index}}
                name="deliveryLocation" />
            </div>
            }
            <div class="form_div">
              <button style="background-color: #0f172a" (click)="toggleDeliveryLocationModal()"
                class="flex justify-center p-3 space-x-4 text-white duration-300 create_button hover:shadow-lg"
                type="button">
                <span>Done</span>
              </button>
            </div>
          </form>
          }
        </div>
      </div>
    </ng-template>
  </p-dialog>

  <p-dialog [(visible)]="updateProfileDialog" [style]="{ width: '450px' }" header="Update Details" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
      <div class="w-full m-0 space-y-6 md:flex-col md:p-1 lg:p-3 xl:p-4">
        <form [formGroup]="profileUpdateForm" (submit)="updateProfile()">
          <div class="flex flex-col space-y-3">
            <!-- Cart list -->
            @if(!this.user?.email){
            <div class="form_div">
              <label for="">Email</label>
              <input required class="p-3 border rounded-md" placeholder="Input Your Email" formControlName="email"
                type="text" required />
            </div>
            }
            <div class="form_div">
              <label for="">Phone Number</label>
              <input required class="p-3 border rounded-md" placeholder="Input Your Phone" formControlName="phone"
                type="text" required />
            </div>
            <div class="form_div">
              <label for="">Address</label>
              <input required class="p-3 border rounded-md" placeholder="Enter Your Address" formControlName="address"
                type="text" required />
            </div>
            <div class="form_div">
              <button style="background-color: #0f172a"
                class="flex justify-center p-3 space-x-4 text-white duration-300 create_button hover:shadow-lg"
                type="submit">
                <span>Proceed to checkout</span>
              </button>
            </div>
            <div class="flex flex-col justify-between w-full mt-4">
              <app-info textItem="This is to enable vendors contact you regarding your orders"></app-info>
            </div>
          </div>
        </form>
      </div>
    </ng-template>

  </p-dialog>


  <!-- Mobile Div -->
  <div class="flex flex-col pt-4 md:hidden">
    <div class="px-5 space-y-3">
      <div class="flex flex-col space-y-3">
        <span class="font-bold">Search Food</span>
        <div class="relative flex items-center w-full">
          <input class="py-3 pl-12 px-1 rounded-lg w-full bg-[#7676801f] focus:outline-none" type="text" name=""
            placeholder="Search" id="" disabled />
          <button class="absolute pl-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 text-gray-300 duration-200 hover:scale-110"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="10" cy="10" r="7" />
              <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex flex-col space-y-0">
        <span class="mb-1 font-bold">Food Categories</span>
        <div class="flex py-2 overflow-x-auto">
          @for( category of categories;track category.id){
          <div (click)="categoryProducts($event, category.id)" class="category_item_div group"
            [ngClass]="{'text-white bg-[#0F172A]':activeCategory === category.id}">
            <span class="flex whitespace-nowrap px-1 text-[#0F172A] group-hover:text-white"
              [ngClass]="{'text-white':activeCategory === category.id}" href="">{{ category.name
              }}</span>
            <span class="text-[#0F172A] opacity-50 group-hover:text-white"
              [ngClass]="{'text-white':activeCategory === category.id}">{{
              category.quantity
              }}</span>
          </div>
          }
        </div>
      </div>
    </div>
  </div>

  <!-- Content Div -->
  <div
    class="flex items-center justify-between w-full px-1 pt-4 mb-20 space-x-5 lg:px-10 xl:px-12 2xl:px-24 lg:space-x-8 md:items-start">
    <!-- Categories Div -->
    <div
      class="hidden md:w-[25%] lg:w-[22%] 2xl:w-[27%] p-0 shadow-sm md:p-2 lg:py-4 lg:px-2 h-fit md:flex md:flex-col bg-[#0f172a08] space-y-6">
      <!-- Search Div -->
      <!-- <div class="flex flex-col space-y-3">
        <span class="font-bold">Search Food</span>
        <div class="flex items-center w-full">
          <input class="py-3 pl-12 px-1 rounded-lg w-full bg-[#7676801f] focus:border focus:border-[#E91E63]"
            type="text" name="" placeholder="Search" id="" disabled />
          <button class="absolute pl-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-8 text-gray-300 duration-200 hover:scale-110"
              viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <circle cx="10" cy="10" r="7" />
              <line x1="21" y1="21" x2="15" y2="15" />
            </svg>
          </button>
        </div>
      </div> -->
      <div class="flex flex-col space-y-0">
        <span class="sm:mb-2 lg:mb-4">Food Categories</span>
        <div class="overflow-auto">
          @for(category of categories; track category; let i = $index ){
          <app-side-menu-item [active]="activeCategory === category.id" [index]="i"
            (click)="categoryProducts($event, category.id)" [menuName]="category.name"
            [menuProp]="category.quantity + ''"></app-side-menu-item>
          }
        </div>
      </div>
    </div>
    <!-- Products Div -->
    <div class="w-[95%] md:w-[44%] lg:w-[44%] 2xl:w-full flex-auto">
      <!-- List of Products -->
      <div class="px-0 sm:px-1 md:px-2 lg:px-3 2xl:px-10">
        @for(product of products; track product.productId){
        <div
          class="flex items-center justify-start w-full py-2 my-1 space-x-0 border-b md:justify-between sm:space-x-2 md:space-x-1 sm:py-1">
          <!-- Food Image and Info -->
          <div class="flex flex-row w-[60%] md:w-[70%] xl:w-3/4 space-x-3 sm:space-x-1 xl:space-x-4 sm:items-center">
            <!-- <img class="product_image" src="" alt="product image" /> -->
            <!-- Info -->
            <div class="flex flex-col justify-start space-y-1 text-sm text-vendblocblue">
              <span class="text-lg font-normal ">{{ product.product }}</span>
              <div class="flex items-center justify-start space-x-5 md:space-x-4">
                <span class="text-lg opacity-50 ">Qty</span>
                <span class="flex items-center space-x-1">
                  <span class="bg-gray-300 py-2.5 px-1.5 rounded-md"
                    (click)="updateProductQuantity(product.productId, false)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 13 2" class=""
                      fill="white">
                      <path d="M1 1H12.0194" stroke="#0F172A" stroke-width="1.57419" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  <span><input
                      class="w-7 text-sm text-vendblocblue align-middle rounded-sm lg:rounded-md p-1 px-0.5 text-center"
                      min=0 value="{{ product.orderQuantity }}" name="" id="" /></span>

                  <span class="bg-gray-300 py-1.5 px-1.5 rounded-md"
                    (click)="updateProductQuantity(product.productId, true)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 13 14" fill="none">
                      <path d="M6.52881 1.49023V12.5096" stroke="#0F172A" stroke-width="1.57419" stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path d="M1.01953 7H12.0389" stroke="#0F172A" stroke-width="1.57419" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                </span>
              </div>
              <div class="flex space-x-1 text-lg">
                <span class="opacity-50 ">Price</span>
                <div class="flex justify-center w-full">
                  <span class="opacity-70 ">{{outlet?.currency}}{{
                    product.salesPrice * product.orderQuantity }}</span>
                </div>

              </div>
            </div>
          </div>
          <!-- Add To Cart -->
          <div class="flex w-[40%] justify-end pr-1 sm:w-1/2 xl:w-1/4">
            <button (click)="addToCart(product)" style="background-color: #0f172a" class="add_to_cart">
              <span class="text-white">Add to cart</span>
            </button>
          </div>
        </div>
        } @if(products.length === 0){
        <div class="text-lg text-center md:text-xl">
          @if(currentCategory === -1){
          <div class="flex items-center justify-center">
            <span>Please select a category to see the products</span>
          </div>
          } @else {
          <div class="flex items-center justify-center">
            <span>There are no products in this current category</span>
          </div>
          }
        </div>
        }
      </div>
      <!-- Product item -->
    </div>

    <!-- My Cart -->
    <div
      class="hidden md:flex m-0 md:flex-col md:w-[37%] lg:w-[31%] 2xl:w-[55%] shadow-sm md:p-1 lg:p-3 xl:p-4 bg-[#0f172a08] space-y-6">
      <div class="flex flex-col space-y-3">
        <div class="flex justify-between">
          <span class="font-bold">My Cart {{this.cartCount > 0 ? (this.cart.length > 1 ? (this.cart.length+' orders') :
            (this.cartCount+' item(s)')
            ) : '' }} </span>
          <span>
            @if(this.cart.length > 0){
            <i (click)="clearCart()" class="text-xl pi pi-trash"></i>
            }
          </span>
        </div>

        <!-- Cart list -->
        <div class="flex max-h-[300px] flex-col overflow-auto">
          @for(orderCart of cart; track orderCart; let i = $index){
          @if(this.cart.length > 1){
          <span class="font-semibold">Order {{ i+ 1}} ({{ orderCart.length }})</span>
          }
          @for(orderItem of orderCart; track orderItem.id){
          <app-cart-item [currency]="outlet?.currency" (updateQuantityEmit)="updateCartQuanity($event)"
            (cartItemEmitted)="removeFromCart($event, i)" [cartItem]="orderItem"></app-cart-item>
          }
          }
        </div>
        @if(cartCount > 0){
        <app-delivery-option [enableDelivery]="this.outlet?.delivery" [defaultDeliveryMode]="selectedDeliveryMode"
          (selectedDeliveryModeEmitter)="deliveryModeChanged($event)"></app-delivery-option>
        <div class="space-y-1">
          <span class="flex font-semibold">Item(s) Cost : {{ ordersCost }}</span>
          <span class="flex font-semibold">Service Charge : {{ serviceCharge }}</span>
          @if(this.outlet?.delivery && this.selectedDeliveryMode === 1){
          <span class="flex font-semibold">Delivery Cost : {{ deliveryFee }} ({{this.deliveryLocation}})</span>
          }
        </div>
        }
        <div class="form_div">
          @if(cartCount > 0){
          <button (click)="newOrder()" style="background-color: #E7E8EA"
            class="flex justify-center p-3 space-x-4 duration-300 create_button text-vendblocblue hover:shadow-lg"
            type="submit">
            <span>New Order</span>
          </button>
          <button (click)="checkout()" style="background-color: #0f172a"
            class="flex justify-center p-3 space-x-4 text-white duration-300 create_button hover:shadow-lg"
            type="submit">
            <span>Checkout ({{outlet?.currency}} {{ totalcost }})</span>
          </button>
          }

          <!-- <span [ngClass]="{'text-green-500' : cartCount>0}">Adesina</span> -->
        </div>
      </div>
    </div>

    <!-- Floating Action -->
    <p (click)="showDialog()"
      class="md:hidden bg-vendblocblue z-50 shadow-lg fixed px-3 py-2 rounded-full right-[50px] bottom-20 hover:px-4 hover:py-3 duration-100 mb-12">
      <i class="text-2xl text-white pi pi-shopping-cart" pBadge [value]="cartCount.toString()"></i>
    </p>
  </div>
</div>
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService, InventoryService, LoadingService, LocalService, OrderService, OutletService } from '@data-access/services';
import { CartHolder, CreateOrder, Inventory, InventoryByCategory, Outlet, ProductCategory, ResponseDTO, User } from '@models/index';
import { ToastrService } from 'ngx-toastr';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { Subject, first, takeUntil } from 'rxjs';
import { CartItemComponent } from "../cart-item/cart-item.component";
import { DeliveryOptionComponent } from "../delivery-option/delivery-option.component";
import { SideMenuItemComponent } from "../side-menu-item/side-menu-item.component";
import { DialogModule } from 'primeng/dialog';
import { InfoComponent } from "../info/info.component";
import { BadgeModule } from 'primeng/badge';
import { PaymentPercentage } from '@utils/index';

@Component({
  selector: 'app-order',
  standalone: true,
  templateUrl: './order.component.html',
  styleUrl: './order.component.css',
  imports: [SideMenuItemComponent,
    CommonModule,
    CartItemComponent,
    FormsModule,
    ReactiveFormsModule,
    DeliveryOptionComponent, ButtonModule,
    CardModule,
    OverlayPanelModule, DialogModule, InfoComponent, BadgeModule]
})
export class OrderComponent implements OnInit, OnDestroy {
  cartDialog: boolean = false;
  deliveryLocation = 'General';
  deliveryLocationDialog: boolean = false;
  updateProfileDialog: boolean = false;
  outlet: Outlet | undefined = undefined;
  categories: ProductCategory[] = [];
  allproducts: Inventory[] = [];
  products: Inventory[] = [];
  cart: Inventory[][] = [];
  cartCount: number = 0;
  deliveryFee: number = 0;
  ordersCost: number = 0;
  totalcost: number = 0;
  currentCategory = -1;
  selectedDeliveryMode: number = 0;
  selectedDeliveryLocation: number = 0;
  serviceCharge: number = 0;
  baseServiceCharge: number = 150;
  user: User | null = null;
  //currentOrder: number = 0;
  activeCategory: number = -1;
  confirmDeliveryInfo: boolean = false;

  constructor(private toastr: ToastrService,
    private orderService: OrderService,
    private outletService: OutletService,
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private authService: AuthService,
    private inventoryService: InventoryService,
    private local: LocalService) {
  }

  private $destroy = new Subject<void>();

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.fetchOutlet(params['slug']);
    });

    this.authService.user$.pipe(takeUntil(this.$destroy)).subscribe({
      next: (result) => {
        this.user = result as User;
        this.profileUpdateForm.patchValue({ phone: this.user?.phone, address: this.user?.address, email: this.user?.email });
      },
      error: () => {
      }
    });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  newOrder() {
    if (this.cartDialog) {
      this.cartDialog = false;
    }
    if (this.cart[this.cart.length - 1]?.length) {
      this.cart.push([]);
      this.toastr.info('Starting a new order', 'New Order');
    }
    else{
      this.toastr.info('You already started a new order', 'New Order');
    }
   
  }

  profileUpdateForm = new FormGroup({
    address: new FormControl(),
    email: new FormControl(),
    phone: new FormControl(),
  });


  sortOutExistingCart() {
    const existingCart = this.local.getData("cart");
    if (existingCart !== null) {
      const savedCart: CartHolder = JSON.parse(existingCart);
      if ((!this.isOlderThanTenMinutes(savedCart.timestamp) && savedCart.outletId === this.outlet?.id)) {
        if (savedCart.userId === this.user?.id) {
          this.cart = savedCart.cart;
          const flattenedOrders = this.cart.flat();
          this.cartCount = flattenedOrders.length;
          // Extract the 'price' property from each object and sum them up
          this.ordersCost = flattenedOrders.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.price;
          }, 0);
          this.calculateTotalCost();
        }
      }
      else {
        this.local.removeData("cart");
      }
    }
  }

  isOlderThanTenMinutes(millisToCheck: number): boolean {
    const THIRTY_MINUTES = 30 * 60 * 1000;
    const now = new Date().getTime();
    const thirtyMinutesAgo = now - THIRTY_MINUTES;
    return millisToCheck < thirtyMinutesAgo;
  }

  showDialog() {
    this.cartDialog = !this.cartDialog;
  }

  saveCartToLocalStorage(): void {
    const now = new Date().getTime();
    const saveCart: CartHolder = {
      cart: this.cart,
      outletId: this.outlet?.id as number,
      userId: this.user?.id as string,
      timestamp: now
    }
    this.local.saveData("cart", JSON.stringify(saveCart));
  }

  updateProfile() {
    this.loadingService.isLoading.next(true);
    const formValue = this.profileUpdateForm.value;
    if (!this.user?.email) {
      if (formValue.email === "") {
        this.loadingService.isLoading.next(false);
        this.toastr.warning("Email is required");
        return;
      }
    }
    if (formValue.address !== "" && formValue.phone !== "") {
      this.loadingService.isLoading.next(false);
      this.toggleProfileModal();
      this.confirmDeliveryInfo = true;
      // if(this.user){
      //   this.user.address = formValue.address;
      //   this.user.phone = formValue.phone;
      // }
      this.checkout();
      return;
    }
    this.loadingService.isLoading.next(false);
  }

  fetchReferralGifts(userId: string) {
    this.authService.getEligibleReferralPackages(userId).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const gifts = result.data as string[];
          gifts.forEach(element => {
            this.toastr.success(element);
          });
        }
        else {
        }
      },
      error: () => {
        console.log("Something went wrong");
      }
    });
  }

  fetchOutlet(outletSlug: string) {
    this.outletService.getOutletBySlug(outletSlug).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.outlet = result.data as Outlet;
          this.fetchInventoryByCategory(this.outlet.id);
          if (this.outlet?.delivery) {
            this.deliveryFee += this.outlet?.deliveryFee as number;
          }
          this.sortOutExistingCart();
        }
        else {
          console.log("something went wrong");
        }
      },
      error: (ex) => {
        console.log(ex);
        console.log("Something went wrong");
      }
    });
  }

  fetchInventoryByCategory(outlet: number) {
    this.inventoryService.getInventoryByCategoryByOutlet(outlet).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          const inventory = result.data as InventoryByCategory[];
          this.allproducts = inventory.flatMap(x => x.inventories);
          this.categories = inventory.map(x => ({
            name: x.productCategory,
            id: x.productCategoryId,
            quantity: x.inventoryQuantity
          } as ProductCategory));
        }
      }
    });
  }

  validateAddressAndPhone(): boolean {
    if (!this.user?.phone && !this.profileUpdateForm.value.phone) {
      return false;
    }
    return true;
  }

  deliveryLoctionChanged(index: number): void {
    if (this.outlet) {
      const location = this.outlet?.deliveryLocations[index];
      this.totalcost += -this.deliveryFee;
      this.deliveryLocation = location.location;
      const now = new Date().getHours();
      this.outlet.deliveryFee = now >= 18 && location?.nightAmount !== 0
        ? location?.nightAmount
        : location?.dayAmount;
      this.deliveryModeChanged(true);
    }
  }

  toggleDeliveryLocationModal() {
    this.deliveryLocationDialog = !this.deliveryLocationDialog;
  }

  deliveryModeChanged(delivery: boolean): void {
    const deliveryFeeChange = delivery ? (this.outlet?.deliveryFee as number) : -this.deliveryFee;
    this.totalcost += deliveryFeeChange;
    this.deliveryFee = delivery ? deliveryFeeChange : 0;
    this.selectedDeliveryMode = delivery ? 1 : 0;
    if (this.selectedDeliveryMode === 1 && this.outlet && this.outlet.deliveryLocations.length > 0) {
      this.toggleDeliveryLocationModal();
    }
    this.updateServiceCharge();
  }

  categoryProducts(event: Event, productCategoryId: number) {
    event.preventDefault();
    this.activeCategory = productCategoryId;
    this.currentCategory = productCategoryId;
    this.products = this.allproducts.filter(x => x.productCategoryId === productCategoryId);
  }

  updateServiceCharge() {
    const gateway = PaymentPercentage.SquadPay;
    const gatewayCharge = (this.ordersCost * (gateway / 100));
    const vendBlocCharge = (this.ordersCost * (PaymentPercentage.VendBloc / 100));
    let serviceCharge = gatewayCharge + vendBlocCharge;
    if (this.ordersCost > 2000 && gateway == PaymentPercentage.Paystack) {
      serviceCharge = serviceCharge + 100;
    }
    this.serviceCharge = Math.round(serviceCharge);
  }

  clearCart() {
    this.cart = [];
    this.cartCount = 0;
    this.ordersCost = 0;
    this.updateServiceCharge();
  }

  calculateTotalCost() {
    this.updateServiceCharge();
    this.totalcost = this.ordersCost + this.serviceCharge;
    if (this.selectedDeliveryMode === 1) {
      this.totalcost += this.deliveryFee;
    }
  }

  addToCart(product: Inventory) {
    if (product.orderQuantity < 1) {
      this.toastr.warning("Please enter a valid quantity");
      return;
    }
    const price = product.salesPrice * product.orderQuantity;
    product.price = price;
    this.ordersCost = this.ordersCost + (product.price);
    this.calculateTotalCost();
    if(!this.cart.length){
      this.cart.push([]);
    }
    if (this.cart.length) {
      const currentCart: Inventory[] = this.cart[this.cart.length - 1];
      const isProductInCurrentCart = currentCart.findIndex(x => x.productId === product.productId);
      if (isProductInCurrentCart < 0) {
        currentCart.push({ ...product, group: this.cart.length });
        this.cartCount++;
      }
      else {
        currentCart[isProductInCurrentCart].orderQuantity += product.orderQuantity;
        currentCart[isProductInCurrentCart].price += (product.price);
      }
    }
    product.orderQuantity = 1;
    this.saveCartToLocalStorage()
    this.toastr.success('Added To Cart', 'Success');
  }

  updateProductQuantity(productId: number, increment: boolean) {
    const index = this.products.findIndex(x => x.productId === productId);
    if (increment) {
      this.products[index].orderQuantity = this.products[index].orderQuantity + 1;
    }
    else {
      if (this.products[index].orderQuantity > 1) {
        this.products[index].orderQuantity = this.products[index].orderQuantity - 1;
      }
    }
    this.updateServiceCharge();
  }

  removeFromCart(product: any, orderIndex: number) {
    this.cartCount--;
    this.ordersCost = this.ordersCost - product.price;
    const currentCart: Inventory[] = this.cart[orderIndex];
    const itemIndex = currentCart.findIndex(x => x.productId === product.productId);
    if (itemIndex !== -1) {
      currentCart.splice(itemIndex, 1);
      if (currentCart.length < 1) {
        this.cart.splice(orderIndex, 1);
      }
    }
    this.saveCartToLocalStorage();
    this.calculateTotalCost();
  }

  updateCartQuanity(event: { cartItem: Inventory, increment: boolean; }) {
    const orderGroup = event?.cartItem?.group ?? 0;
    const targetOrder: Inventory[] = this.cart[orderGroup - 1];
    const isProductInTargetOrder = targetOrder.findIndex(x => x.productId === event?.cartItem?.productId);
    if (isProductInTargetOrder < 0) {
      this.toastr.info('Product not found');
    }
    else {
      if (event.cartItem.orderQuantity < 2 && event.increment === false) return;
      if (event?.increment) {
        targetOrder[isProductInTargetOrder].orderQuantity++;
        targetOrder[isProductInTargetOrder].price += (event.cartItem.salesPrice);
        this.totalcost += (event.cartItem.salesPrice);
        this.ordersCost += (event.cartItem.salesPrice);
      }
      else {
        targetOrder[isProductInTargetOrder].orderQuantity--;
        targetOrder[isProductInTargetOrder].price -= (event.cartItem.salesPrice);
        this.totalcost -= (event.cartItem.salesPrice);
        this.ordersCost -= (event.cartItem.salesPrice);
      }
    }
    this.saveCartToLocalStorage();
    this.updateServiceCharge();
  }

  toggleProfileModal() {
    this.updateProfileDialog = !this.updateProfileDialog;
    if (this.updateProfileDialog) {
      this.cartDialog = false;
    }
  }

  checkout() {
    const email = this.user?.email as string;
    if (!email) {
      if (this.profileUpdateForm.value.email === email) {
        this.toastr.warning("Please ensure your email, phone and address are correct");
        this.toggleProfileModal();
        return;
      }
    }
    if (this.cartCount < 1) {
      this.toastr.warning("Add Items to cart Before Checking Out");
      return;
    }
    if (!this.validateAddressAndPhone()) {
      this.toastr.warning("Please update your phone and address");
      this.toggleProfileModal();
      return;
    }
    else {
      if (!this.confirmDeliveryInfo) {
        this.toastr.warning("Please confirm your phone and address");
        this.toggleProfileModal();
        this.confirmDeliveryInfo = true;
        return;
      }
    }
    this.loadingService.isLoading.next(true);
    const order: CreateOrder = {
      orders: this.cart,
      outletId: this.outlet?.id ?? 0,
      customer: {
        email: this.user?.email || this.profileUpdateForm.value.email,
        address: this.user?.address || this.profileUpdateForm.value.address,
        phone: this.user?.phone || this.profileUpdateForm.value.phone
      },
      amount: this.totalcost,
      deliveryMode: this.selectedDeliveryMode,
      deliveryCost: this.selectedDeliveryMode === 0 ? 0 : this.deliveryFee,
      orderCost: this.ordersCost,
      serviceCharge: this.serviceCharge,
    };
    console.log(order.customer);
    this.orderService.checkout(order).pipe(first()).subscribe({
      next: (result: ResponseDTO) => {
        if (result.status) {
          this.loadingService.isLoading.next(false);
          this.toastr.success('Trying to set up your payment link', 'Order Created');
          this.local.removeData("cart");
          window.location.href = result.data as string;
        }
        else {
          this.toastr.error(result.message);
          this.loadingService.isLoading.next(false);
        }
      },
      error: () => {
        this.loadingService.isLoading.next(false);
      }
    });
  }
}
<app-outlet>
    <app-table [cols]="tableCols" [enableAdd]="true" (addButtonPressed)="showAddDialog()"
        (removeButtonPressed)="deleteInventory($event)" [enableEdit]="true" (editButtonPressed)="showEditDialog($event)" [tableData]="inventories" tableName="Inventory"></app-table>

    <form [formGroup]="createInventoryForm" (submit)="addInventory()">
        <p-dialog [(visible)]="addDialog" [style]="{ width: '700px', height:'500px' }" header="Add Inventory"
            [modal]="true" styleClass="p-fluid">

            <ng-template pTemplate="content">
                <div class="mb-2 form_div">
                    <label for="">Product</label>
                    <p-dropdown class="border rounded-md border-vendblocblue" [filter]="true" filterBy="name" [options]="products" formControlName="product"
                        placeholder="Select a product" optionLabel="name" inputId="float-label"></p-dropdown>
                </div>

                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <label for="">Cost Price</label>
                        <p-inputNumber class="p-3 border rounded-md border-vendblocblue" inputId="locale-user" formControlName="costPrice"
                            [minFractionDigits]="2"></p-inputNumber>
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <label for="">Sales Price</label>
                        <p-inputNumber class="p-3 border rounded-md border-vendblocblue" inputId="locale-user" formControlName="salesPrice"
                            [minFractionDigits]="2"></p-inputNumber>
                    </div>
                </div>

                <div class="mb-2 form_div">
                    <label for="">Quantity</label>
                    <p-inputNumber class="p-3 border rounded-md border-vendblocblue" inputId="locale-user"
                        formControlName="quantity"></p-inputNumber>
                </div>

                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <label for="">Manufacturing Date</label>
                        <input class="p-3 border rounded-md border-vendblocblue" formControlName="manufacturingDate" type="date">
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <label for="">Expiring Date</label>
                        <input class="p-3 border rounded-md border-vendblocblue" type="date" formControlName="expiringDate">
                    </div>
                </div>

                <div class="mb-2 form_div">
                    <label for="">Remark</label>
                    <input class="p-3 border rounded-md border-vendblocblue" formControlName="remark" type="text">
                </div>

            </ng-template>

            <ng-template pTemplate="footer">
                <div class="flex items-end justify-end">
                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocblue" type="submit">Create</button>
                </div>
            </ng-template>

        </p-dialog>
    </form>

    <form [formGroup]="editInventoryForm" (submit)="editInventory()">
        <p-dialog [(visible)]="editDialog" [style]="{ width: '700px', height:'500px' }" header="Edit {{currentInventory?.product}} "
            [modal]="true" styleClass="p-fluid">

            <ng-template pTemplate="content">
                <!-- <div class="mb-2 form_div">
                    <label for="">Product</label>
                    <input [disabled]=true class="p-3 border rounded-md" type="text" formControlName="product"/>
                </div> -->
                <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <label for="">Cost Price</label>
                        <p-inputNumber class="p-3 border rounded-md" inputId="locale-user" formControlName="costPrice"
                            [minFractionDigits]="2"></p-inputNumber>
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <label for="">Sales Price</label>
                        <p-inputNumber class="p-3 border rounded-md" inputId="locale-user" formControlName="salesPrice"
                            [minFractionDigits]="2"></p-inputNumber>
                    </div>
                </div>

                <div class="mb-2 form_div">
                    <label for="">Quantity</label>
                    <p-inputNumber class="p-3 border rounded-md" inputId="locale-user"
                        formControlName="quantity"></p-inputNumber>
                </div>

                <!-- <div class="flex mb-2 space-x-2">
                    <div class="flex flex-col w-[50%]">
                        <label for="">Manufacturing Date</label>
                        <input class="p-3 border rounded-md" formControlName="manufacturingDate" type="date">
                    </div>
                    <div class="flex flex-col w-[50%]">
                        <label for="">Expiring Date</label>
                        <input class="p-3 border rounded-md" type="date" formControlName="expiringDate">
                    </div>
                </div> -->

                <!-- <div class="mb-2 form_div">
                    <label for="">Remark</label>
                    <input class="p-3 border rounded-md" formControlName="remark" type="text">
                </div> -->

            </ng-template>

            <ng-template pTemplate="footer">
                <div class="flex items-end justify-between">
                    @if(currentInventory?.active){
                            <button (click)="toggleAvailable()" class="px-6 py-2 text-white bg-red-500 rounded-sm" type="submit">Disable</button>
                        }@else {
                            <button (click)="toggleAvailable()" class="px-6 py-2 text-white bg-green-500 rounded-sm" type="submit">Enable</button>
                        }

                    <button class="px-6 py-2 text-white rounded-sm bg-vendblocblue" type="submit">Update</button>
                </div>
            </ng-template>

        </p-dialog>
    </form>
</app-outlet>